import React from 'react';

const Logo = () => {
    return (
        <div className='auth_logo'>
                <a href={process.env.REACT_APP_SITE_URL} className="b-brand">
                    <img src='/img/logo.svg' alt='MPEPE logo'/>
                </a>
        </div>
    );
};

export default Logo;