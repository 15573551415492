import axios from 'axios'
import buildURL from 'axios/lib/helpers/buildURL'
import {userSignOutAction} from "../store/actions/authActions";
import {toast} from "react-toastify";
import {getCookie, setCookie} from "./cookies";
import {buildAPIUrl} from "../urls";

let dispatch = undefined;
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

export function GetAxios(dispatcher) {

    dispatch = dispatcher;
    axios.defaults.withCredentials = true
    axios.interceptors.request.use(
        config => {
            config.headers['Content-Type'] = "application/json"
            const token = getCookie('accessToken');
            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }
            return config;
        },
        error => {
            Promise.reject(error)
        });

    axios.interceptors.response.use(response => {
        return response;
    }, err => {

        const originalRequest = err.config;

        if (err.response.status === 401 && originalRequest.url.includes('/refresh/client')) {
            return Promise.reject(err);
        }

        if (err.response.status === 401 && originalRequest.url.includes('/email-confirm')) {
            return Promise.reject(err);
        }

        if (err.response.status === 401 && originalRequest.url.includes('/password-reset')) {
            return Promise.reject(err);
        }

        if (err.response.status === 401 && originalRequest.url.includes('/sign-in')) {
            return Promise.reject(err);
        }

        if (err.response.status === 401 && originalRequest.url.includes('/get-qr')) {
            return Promise.reject(err);
        }

        if (err.response.status === 401 && originalRequest.url.includes('/sign-out')) {
            return Promise.reject(err);
        }

        if (err.response.status === 401 && !originalRequest._retry) {

            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({resolve, reject});
                })
                    .then(token => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        return axios(originalRequest);
                    })
                    .catch(err => {
                        return Promise.reject(err);
                    });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise(function (resolve, reject) {
                axios
                    .get(buildAPIUrl('/refresh/client'), {

                    })
                    .then(({data}) => {
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
                        originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token;
                        setCookie('accessToken', data.access_token, {'max-age': 3600
                            // 'Domain': process.env.REACT_APP_DOMAIN
                        })
                        processQueue(null, data.access_token);
                        resolve(axios(originalRequest));
                    })
                    .catch(err => {
                        processQueue(err, null);
                        toast.warn('Refresh token expired. Please login.');
                        dispatch(userSignOutAction(getCookie('public_id')))
                    })
                    .finally(() => {
                        isRefreshing = false;
                    });
            });
        }

        return Promise.reject(err);
    });

    return axios
}

export function BuildUrl(url, params) {
    return buildURL(url, params)
}