import * as wsAction from '../../actions/wsActions/constants'
import * as tokenAction from '../../actions/tokenActions/constants'

const initialState = {
    tokenPrice: null,
    xmu_left: null,
    rates: null,
    paymentFormData: null,
    isLoading: true,
    wsStatus: 'close',
    paymentPopupData: null,
    isProcessedPayment: false,
    isPromoCodeInfoFetch: false,
    promoError: '',
    promoCodeInfo: null
};

export default (state = initialState, action) => {
    switch (action.type) {

        case tokenAction.GET_PROMO_CODE_INFO_REQUEST:
            return {
                ...state,
                isPromoCodeInfoFetch: true
            };
        case tokenAction.GET_PROMO_CODE_INFO_SUCCESS:
            return {
                ...state,
                isPromoCodeInfoFetch: false,
                promoCodeInfo: action.payload
            };
        case tokenAction.GET_PROMO_CODE_INFO_FAILURE:
            return {
                ...state,
                isPromoCodeInfoFetch: false,
                promoError: action.payload,
                promoCodeInfo: null
            };
        case wsAction.WS_ON_CONNECT:
            return {
                ...state,
                wsStatus: 'connecting'
            };
        case wsAction.WS_ON_OPEN:
            return {
                ...state,
                wsStatus: 'open'
            };
        case wsAction.WS_ON_CLOSE:
            return {
                ...state,
                wsStatus: 'close'
            };
        case wsAction.WS_ON_MESSAGE:
            return {
                ...state,
                xmu_left: action.payload.xmu_left,
                tokenPrice: action.payload.mpeppe_price,
                rates: action.payload
            };
        case tokenAction.GET_PAYMENT_FORM_DATA_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case tokenAction.GET_PAYMENT_FORM_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                paymentFormData: action.payload
            };
        case tokenAction.GET_PAYMENT_FORM_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
                paymentFormData: null
            };
        case tokenAction.CREATE_PAYMENT_REQUEST:
            return {
                ...state,
                isLoading: true,
                isProcessedPayment: true,
            };
        case tokenAction.CREATE_PAYMENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                paymentPopupData: action.payload,
                isProcessedPayment: false
            };
        case tokenAction.CREATE_PAYMENT_FAILURE:
            return {
                ...state,
                isLoading: false,
                paymentPopupData: null,
                isProcessedPayment: false
            };
        default:
            return state
    }
}